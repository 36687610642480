import axios from 'axios';

const xWhoApi = 'gcp-hostedpage';
const piDataIdHeader = 'X-HIPAY-DATA-ID';
const backend_url = process.env.REACT_APP_HIPAY_BACKEND_URL;
const backend_api_key = process.env.REACT_APP_HIPAY_BACKEND_API_KEY;

const gateway = {
  stage: 'https://stage-secure-gateway.hipay-tpp.com/rest',
  production: 'https://secure-gateway.hipay-tpp.com/rest'
};

class InternalError extends Error {
  constructor(forward_url) {
    super('internal');
    this.status = 'internal';
    this.forward_url = forward_url;
  }
}

// Validate page from token_url
// Returns page_token and status
export const validatePage = (token_url) => {
  return axios
    .post(
      backend_url + '/token/validate',
      {
        token_url: token_url
      },
      {
        params: {
          key: backend_api_key
        },
        headers: {
          'X-Who-Api': xWhoApi
        }
      }
    )
    .then((result) => {
      return result.data;
    });
};

// Call API transaction
// API transaction call API Order
export const processOrder = (token_auth, token_url, payment) => {
  return axios
    .post(
      backend_url + '/transaction',
      {
        token_url,
        payment
      },
      {
        params: {
          key: backend_api_key
        },
        headers: {
          'X-Who-Api': xWhoApi,
          Authorization: `Bearer ${token_auth}`,
          [piDataIdHeader]: payment.data_id
        }
      }
    )
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      // Throw error if correct API return
      if (
        error?.response?.data?.status ||
        error?.response?.data?.error?.code === 'paymentservice.page_expired'
      ) {
        throw error.response.data;
      } else {
        // Log internal error and return generic error with forward URL
        console.error(error);
        throw new InternalError(
          error?.response?.data?.error?.details?.forward_url
        );
      }
    });
};

export const getAvailablePaymentProducts = (
  client,
  country = '',
  currency = '',
  payment_product_list
) => {
  let endpoint =
    gateway[
      process.env.REACT_APP_HIPAY_ENV === 'production' ? 'production' : 'stage'
    ] + '/v2/available-payment-products';

  let requestParams = {
    eci: 7,
    customer_country: country,
    currency: currency,
    payment_product: payment_product_list.join()
  };

  return axios
    .get(endpoint, {
      params: requestParams,
      headers: {
        Authorization: `Basic ${client.auth_token}`
      }
    })
    .then((response) => {
      return response.data;
    });
};
